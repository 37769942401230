export const jQuery = {
    testimonialSlider: function() {
        if (process.client) {
            if ($('.pxp-areas-carousel-stage.testimonial-carousel-stage').length > 0) {
                $('.pxp-areas-carousel-stage.testimonial-carousel-stage').owlCarousel({
                    'nav': true,
                    'dots': false,
                    'margin': 35,
                    autoWidth: true,
                    'responsiveClass' :true,
                    'responsive': {
                        0: {
                            'items': 1
                        },
                        991: {
                            'items': 3
                        }
                    },
                    'navText': [`<div class="pxp-areas-carousel-left-arrow pxp-animate" style="margin-top: 64px">
                                <svg xmlns="http://www.w3.org/2000/svg" width="32.414" height="20.828" viewBox="0 0 32.414 20.828" class="pxp-arrow-1">
                                    <g id="Group_30" data-name="Group 30" transform="translate(-1845.086 -1586.086)">
                                        <line id="Line_2" data-name="Line 2" x1="30" transform="translate(1846.5 1596.5)" fill="none" stroke="#333" stroke-linecap="round" stroke-width="2"/>
                                        <line id="Line_3" data-name="Line 3" x1="9" y2="9" transform="translate(1846.5 1587.5)" fill="none" stroke="#333" stroke-linecap="round" stroke-width="2"/>
                                        <line id="Line_4" data-name="Line 4" x1="9" y1="9" transform="translate(1846.5 1596.5)" fill="none" stroke="#333" stroke-linecap="round" stroke-width="2"/>
                                    </g>
                                </svg>
                            </div>`,
                        `<div class="pxp-areas-carousel-right-arrow pxp-animate" style="margin-top: 64px">
                                <svg xmlns="http://www.w3.org/2000/svg" width="32.414" height="20.828" viewBox="0 0 32.414 20.828">
                                    <g id="Symbol_1_1" data-name="Symbol 1 – 1" transform="translate(-1847.5 -1589.086)">
                                        <line id="Line_2" data-name="Line 2" x2="30" transform="translate(1848.5 1599.5)" fill="none" stroke="#333" stroke-linecap="round" stroke-width="2"/>
                                        <line id="Line_3" data-name="Line 3" x2="9" y2="9" transform="translate(1869.5 1590.5)" fill="none" stroke="#333" stroke-linecap="round" stroke-width="2"/>
                                        <line id="Line_4" data-name="Line 4" y1="9" x2="9" transform="translate(1869.5 1599.5)" fill="none" stroke="#333" stroke-linecap="round" stroke-width="2"/>
                                    </g>
                                </svg>
                            </div>`],
                    'checkVisible': false,
                    'smartSpeed': 600
                });
            }
        }
    },


    propertiesSlider: function() {
        // Areas section carousel
        if ($('.pxp-areas-carousel-stage.properties-carousel-stage').length > 0) {
            $('.pxp-areas-carousel-stage.properties-carousel-stage').owlCarousel({
                'nav': true,
                'dots': false,
                'margin': 35,
                'responsive': {
                    0: {
                        'items': 1
                    },
                    600: {
                        'items': 1
                    },
                    900: {
                        'items': 1
                    },
                    1200: {
                        'items': 3
                    },
                    1600: {
                        'items': 3
                    }
                },
                'navText': [`<div class="pxp-areas-carousel-left-arrow pxp-animate">
                            <svg xmlns="http://www.w3.org/2000/svg" width="32.414" height="20.828" viewBox="0 0 32.414 20.828" class="pxp-arrow-1">
                                <g id="Group_30" data-name="Group 30" transform="translate(-1845.086 -1586.086)">
                                    <line id="Line_2" data-name="Line 2" x1="30" transform="translate(1846.5 1596.5)" fill="none" stroke="#333" stroke-linecap="round" stroke-width="2"/>
                                    <line id="Line_3" data-name="Line 3" x1="9" y2="9" transform="translate(1846.5 1587.5)" fill="none" stroke="#333" stroke-linecap="round" stroke-width="2"/>
                                    <line id="Line_4" data-name="Line 4" x1="9" y1="9" transform="translate(1846.5 1596.5)" fill="none" stroke="#333" stroke-linecap="round" stroke-width="2"/>
                                </g>
                            </svg>
                        </div>`,
                    `<div class="pxp-areas-carousel-right-arrow pxp-animate">
                            <svg xmlns="http://www.w3.org/2000/svg" width="32.414" height="20.828" viewBox="0 0 32.414 20.828">
                                <g id="Symbol_1_1" data-name="Symbol 1 – 1" transform="translate(-1847.5 -1589.086)">
                                    <line id="Line_2" data-name="Line 2" x2="30" transform="translate(1848.5 1599.5)" fill="none" stroke="#333" stroke-linecap="round" stroke-width="2"/>
                                    <line id="Line_3" data-name="Line 3" x2="9" y2="9" transform="translate(1869.5 1590.5)" fill="none" stroke="#333" stroke-linecap="round" stroke-width="2"/>
                                    <line id="Line_4" data-name="Line 4" y1="9" x2="9" transform="translate(1869.5 1599.5)" fill="none" stroke="#333" stroke-linecap="round" stroke-width="2"/>
                                </g>
                            </svg>
                        </div>`],
                'checkVisible': false,
                'smartSpeed': 600
            });
        }
    },

    propertiesWideCardSlider: function() {
        // Areas section carousel
        if ($('.pxp-areas-carousel-stage.properties-wide-card-carousel-stage').length > 0) {
            $('.pxp-areas-carousel-stage.properties-wide-card-carousel-stage').owlCarousel({
                stagePadding: 0,
                'nav': true,
                'dots': false,
                'margin': 35,
                autoWidth: true,
                'responsive': {
                    0: {
                        'items': 1
                    },
                    600: {
                        'items': 1
                    },
                    900: {
                        'items': 1
                    },
                    1200: {
                        'items': 3
                    },
                    1600: {
                        'items': 2
                    }
                },
                'navText': [`<div class="pxp-areas-carousel-left-arrow pxp-animate">
                            <svg xmlns="http://www.w3.org/2000/svg" width="32.414" height="20.828" viewBox="0 0 32.414 20.828" class="pxp-arrow-1">
                                <g id="Group_30" data-name="Group 30" transform="translate(-1845.086 -1586.086)">
                                    <line id="Line_2" data-name="Line 2" x1="30" transform="translate(1846.5 1596.5)" fill="none" stroke="#333" stroke-linecap="round" stroke-width="2"/>
                                    <line id="Line_3" data-name="Line 3" x1="9" y2="9" transform="translate(1846.5 1587.5)" fill="none" stroke="#333" stroke-linecap="round" stroke-width="2"/>
                                    <line id="Line_4" data-name="Line 4" x1="9" y1="9" transform="translate(1846.5 1596.5)" fill="none" stroke="#333" stroke-linecap="round" stroke-width="2"/>
                                </g>
                            </svg>
                        </div>`,
                    `<div class="pxp-areas-carousel-right-arrow pxp-animate">
                            <svg xmlns="http://www.w3.org/2000/svg" width="32.414" height="20.828" viewBox="0 0 32.414 20.828">
                                <g id="Symbol_1_1" data-name="Symbol 1 – 1" transform="translate(-1847.5 -1589.086)">
                                    <line id="Line_2" data-name="Line 2" x2="30" transform="translate(1848.5 1599.5)" fill="none" stroke="#333" stroke-linecap="round" stroke-width="2"/>
                                    <line id="Line_3" data-name="Line 3" x2="9" y2="9" transform="translate(1869.5 1590.5)" fill="none" stroke="#333" stroke-linecap="round" stroke-width="2"/>
                                    <line id="Line_4" data-name="Line 4" y1="9" x2="9" transform="translate(1869.5 1599.5)" fill="none" stroke="#333" stroke-linecap="round" stroke-width="2"/>
                                </g>
                            </svg>
                        </div>`],
                'checkVisible': false,
                'smartSpeed': 600
            });
        }
    },

    knowledgeHubSlider: function() {
        // Areas section carousel
        if ($('.pxp-areas-carousel-stage.knowledge-hub-carousel-stage').length > 0) {
            $('.pxp-areas-carousel-stage.knowledge-hub-carousel-stage').owlCarousel({
                'nav': true,
                'dots': false,
                'margin': 20,
                'responsive': {
                    0: {
                        'items': 1
                    },
                    600: {
                        'items': 2
                    },
                    900: {
                        'items': 3
                    },
                    1200: {
                        'items': 4
                    },
                    1600: {
                        'items': 4
                    }
                },
                'navText': [`<div class="pxp-areas-carousel-left-arrow pxp-animate">
                            <svg xmlns="http://www.w3.org/2000/svg" width="32.414" height="20.828" viewBox="0 0 32.414 20.828" class="pxp-arrow-1">
                                <g id="Group_30" data-name="Group 30" transform="translate(-1845.086 -1586.086)">
                                    <line id="Line_2" data-name="Line 2" x1="30" transform="translate(1846.5 1596.5)" fill="none" stroke="#333" stroke-linecap="round" stroke-width="2"/>
                                    <line id="Line_3" data-name="Line 3" x1="9" y2="9" transform="translate(1846.5 1587.5)" fill="none" stroke="#333" stroke-linecap="round" stroke-width="2"/>
                                    <line id="Line_4" data-name="Line 4" x1="9" y1="9" transform="translate(1846.5 1596.5)" fill="none" stroke="#333" stroke-linecap="round" stroke-width="2"/>
                                </g>
                            </svg>
                        </div>`,
                    `<div class="pxp-areas-carousel-right-arrow pxp-animate">
                            <svg xmlns="http://www.w3.org/2000/svg" width="32.414" height="20.828" viewBox="0 0 32.414 20.828">
                                <g id="Symbol_1_1" data-name="Symbol 1 – 1" transform="translate(-1847.5 -1589.086)">
                                    <line id="Line_2" data-name="Line 2" x2="30" transform="translate(1848.5 1599.5)" fill="none" stroke="#333" stroke-linecap="round" stroke-width="2"/>
                                    <line id="Line_3" data-name="Line 3" x2="9" y2="9" transform="translate(1869.5 1590.5)" fill="none" stroke="#333" stroke-linecap="round" stroke-width="2"/>
                                    <line id="Line_4" data-name="Line 4" y1="9" x2="9" transform="translate(1869.5 1599.5)" fill="none" stroke="#333" stroke-linecap="round" stroke-width="2"/>
                                </g>
                            </svg>
                        </div>`],
                'checkVisible': false,
                'smartSpeed': 600
            });
        }
    },

    knowledgeHubSliderSidebar: function() {
        // Areas section carousel
        if ($('.pxp-areas-carousel-stage.knowledge-hub-carousel-stage-sidebar').length > 0) {
            $('.pxp-areas-carousel-stage.knowledge-hub-carousel-stage-sidebar').owlCarousel({
                'nav': true,
                'dots': false,
                'margin': 20,
                'responsive': {
                    0: {
                        'items': 1
                    },
                    600: {
                        'items': 1
                    },
                    900: {
                        'items': 1
                    },
                    1200: {
                        'items': 1
                    },
                    1600: {
                        'items': 1
                    }
                },
                'navText': [`<div class="pxp-areas-carousel-left-arrow pxp-animate">
                            <svg xmlns="http://www.w3.org/2000/svg" width="32.414" height="20.828" viewBox="0 0 32.414 20.828" class="pxp-arrow-1">
                                <g id="Group_30" data-name="Group 30" transform="translate(-1845.086 -1586.086)">
                                    <line id="Line_2" data-name="Line 2" x1="30" transform="translate(1846.5 1596.5)" fill="none" stroke="#333" stroke-linecap="round" stroke-width="2"/>
                                    <line id="Line_3" data-name="Line 3" x1="9" y2="9" transform="translate(1846.5 1587.5)" fill="none" stroke="#333" stroke-linecap="round" stroke-width="2"/>
                                    <line id="Line_4" data-name="Line 4" x1="9" y1="9" transform="translate(1846.5 1596.5)" fill="none" stroke="#333" stroke-linecap="round" stroke-width="2"/>
                                </g>
                            </svg>
                        </div>`,
                    `<div class="pxp-areas-carousel-right-arrow pxp-animate">
                            <svg xmlns="http://www.w3.org/2000/svg" width="32.414" height="20.828" viewBox="0 0 32.414 20.828">
                                <g id="Symbol_1_1" data-name="Symbol 1 – 1" transform="translate(-1847.5 -1589.086)">
                                    <line id="Line_2" data-name="Line 2" x2="30" transform="translate(1848.5 1599.5)" fill="none" stroke="#333" stroke-linecap="round" stroke-width="2"/>
                                    <line id="Line_3" data-name="Line 3" x2="9" y2="9" transform="translate(1869.5 1590.5)" fill="none" stroke="#333" stroke-linecap="round" stroke-width="2"/>
                                    <line id="Line_4" data-name="Line 4" y1="9" x2="9" transform="translate(1869.5 1599.5)" fill="none" stroke="#333" stroke-linecap="round" stroke-width="2"/>
                                </g>
                            </svg>
                        </div>`],
                'checkVisible': false,
                'smartSpeed': 600
            });
        }
    },

    featuredBlogsSlider: function() {
        if (process.client) {
            $('.pxp-blog-posts-carousel-1 .pxp-carousel-control-next').click(function(e) {
                $('.pxp-blog-posts-carousel-1-img').carousel('next');
            });
            $('.pxp-blog-posts-carousel-1 .pxp-carousel-control-prev').click(function(e) {
                $('.pxp-blog-posts-carousel-1-img').carousel('prev');
            });

            $('.pxp-blog-posts-carousel-1-img').on('slide.bs.carousel', function(carousel) {
                if(carousel.direction == 'left') {
                    $('.pxp-blog-posts-carousel-1-caption').carousel('next');
                } else {
                    $('.pxp-blog-posts-carousel-1-caption').carousel('prev');
                }
            });
        }
    },

    blogSidebarSlider: function() {
        // Areas section carousel
        if ($('.pxp-areas-carousel-stage.knowledge-hub-carousel-stage').length > 0) {
            $('.pxp-areas-carousel-stage.knowledge-hub-carousel-stage').owlCarousel({
                'nav': true,
                'dots': false,
                'margin': 20,
                'responsive': {
                    0: {
                        'items': 1
                    },
                    600: {
                        'items': 2
                    },
                    900: {
                        'items': 1
                    },
                    1200: {
                        'items': 1
                    },
                    1600: {
                        'items': 1
                    }
                },
                'navText': [`<div class="pxp-areas-carousel-left-arrow pxp-animate">
                            <svg xmlns="http://www.w3.org/2000/svg" width="32.414" height="20.828" viewBox="0 0 32.414 20.828" class="pxp-arrow-1">
                                <g id="Group_30" data-name="Group 30" transform="translate(-1845.086 -1586.086)">
                                    <line id="Line_2" data-name="Line 2" x1="30" transform="translate(1846.5 1596.5)" fill="none" stroke="#333" stroke-linecap="round" stroke-width="2"/>
                                    <line id="Line_3" data-name="Line 3" x1="9" y2="9" transform="translate(1846.5 1587.5)" fill="none" stroke="#333" stroke-linecap="round" stroke-width="2"/>
                                    <line id="Line_4" data-name="Line 4" x1="9" y1="9" transform="translate(1846.5 1596.5)" fill="none" stroke="#333" stroke-linecap="round" stroke-width="2"/>
                                </g>
                            </svg>
                        </div>`,
                    `<div class="pxp-areas-carousel-right-arrow pxp-animate">
                            <svg xmlns="http://www.w3.org/2000/svg" width="32.414" height="20.828" viewBox="0 0 32.414 20.828">
                                <g id="Symbol_1_1" data-name="Symbol 1 – 1" transform="translate(-1847.5 -1589.086)">
                                    <line id="Line_2" data-name="Line 2" x2="30" transform="translate(1848.5 1599.5)" fill="none" stroke="#333" stroke-linecap="round" stroke-width="2"/>
                                    <line id="Line_3" data-name="Line 3" x2="9" y2="9" transform="translate(1869.5 1590.5)" fill="none" stroke="#333" stroke-linecap="round" stroke-width="2"/>
                                    <line id="Line_4" data-name="Line 4" y1="9" x2="9" transform="translate(1869.5 1599.5)" fill="none" stroke="#333" stroke-linecap="round" stroke-width="2"/>
                                </g>
                            </svg>
                        </div>`],
                'checkVisible': false,
                'smartSpeed': 600
            });
        }
    },

    runAnimation: function() {
        const animateHTML = function() {
            var elems;
            var windowHeight;

            function init() {
                elems = document.querySelectorAll('.pxp-animate-in');
                windowHeight = window.innerHeight;
                addEventHandlers();
                checkPosition();
            }

            function addEventHandlers() {
                window.addEventListener('scroll', checkPosition);
                window.addEventListener('resize', init);
            }

            function checkPosition() {
                for (var i = 0; i < elems.length; i++) {
                    var positionFromTop = elems[i].getBoundingClientRect().top;

                    if (positionFromTop - windowHeight <= 0) {
                        elems[i].classList.add('pxp-in');
                    }
                }
            }

            return {
                init: init
            };
        };


        animateHTML().init();
    }
}