<template>
  <div v-if="styleName === 'v1'">
    <div
      class=""
    >
      <nuxt-link :to="item.link" :external="true">
        <library-image :src="item.photo" :w-ratio="0.78" :h-ratio="1">
          <template v-if="item.is_video" #overlay-item>
            <div class="play-button-on-photo">
              <svg
                width="64"
                height="64"
                viewBox="0 0 64 64"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M32.0001 5.33301C26.7259 5.33301 21.5702 6.89698 17.1848 9.82715C12.7995 12.7573 9.3816 16.9221 7.36327 21.7948C5.34493 26.6675 4.81684 32.0293 5.84578 37.2021C6.87472 42.3749 9.41447 47.1265 13.1439 50.8559C16.8733 54.5853 21.6248 57.125 26.7976 58.154C31.9705 59.1829 37.3323 58.6548 42.2049 56.6365C47.0776 54.6181 51.2424 51.2002 54.1726 46.8149C57.1027 42.4296 58.6667 37.2738 58.6667 31.9997C58.6667 28.4978 57.977 25.0301 56.6368 21.7948C55.2967 18.5594 53.3325 15.6197 50.8562 13.1435C48.38 10.6673 45.4403 8.70301 42.2049 7.36289C38.9696 6.02276 35.502 5.33301 32.0001 5.33301ZM26.6667 43.9997V19.9997L42.6667 31.9997L26.6667 43.9997Z"
                  fill="white"
                />
              </svg>
            </div>
          </template>
        </library-image>
      </nuxt-link>
    </div>
    <div class="pxp-areas-carousel-item-details tw-mt-3">
      <div
        class="pxp-areas-carousel-item-details-city tw-uppercase"
        style="opacity: unset !important"
      >
        <lazy-library-text
          :text="item.is_video ? 'Video' : 'E-Guide'"
          class="tw-font-semibold tw-text-xs"
        >
        </lazy-library-text>
      </div>

      <div class="pxp-areas-carousel-item-details-area tw-mt-1" style="height: 60px; text-overflow: ellipsis">
        <lazy-library-title tag="h5" class="tw-font-bold" :text="item.title">
        </lazy-library-title>
      </div>
    </div>
    <div class="pxp-areas-carousel-item-details">
      <nuxt-link
        style="color: var(--purple)"
        :external="true"
        :to="item.link"
        class="pxp-primary-cta text-uppercase pxp-animate tw-mt-5"
      >
        {{ item.cta }}
      </nuxt-link>
    </div>
  </div>
  <nuxt-link
    v-else-if="styleName === 'v2'"
    :external="true"
    :to="item.link"
    class="pxp-prop-card-1 rounded-lg tw-h-[550px] sm:tw-h-[500px] md:tw-h-[426px]"
  >
    <div
      class="pxp-prop-card-1-fig pxp-cover"
      :style="`background-image: url(${item.photo})`"
    ></div>
    <div v-if="item.is_video" class="play-button-on-photo">
      <svg
        width="64"
        height="64"
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M32.0001 5.33301C26.7259 5.33301 21.5702 6.89698 17.1848 9.82715C12.7995 12.7573 9.3816 16.9221 7.36327 21.7948C5.34493 26.6675 4.81684 32.0293 5.84578 37.2021C6.87472 42.3749 9.41447 47.1265 13.1439 50.8559C16.8733 54.5853 21.6248 57.125 26.7976 58.154C31.9705 59.1829 37.3323 58.6548 42.2049 56.6365C47.0776 54.6181 51.2424 51.2002 54.1726 46.8149C57.1027 42.4296 58.6667 37.2738 58.6667 31.9997C58.6667 28.4978 57.977 25.0301 56.6368 21.7948C55.2967 18.5594 53.3325 15.6197 50.8562 13.1435C48.38 10.6673 45.4403 8.70301 42.2049 7.36289C38.9696 6.02276 35.502 5.33301 32.0001 5.33301ZM26.6667 43.9997V19.9997L42.6667 31.9997L26.6667 43.9997Z"
          fill="white"
        />
      </svg>
    </div>
    <div class="pxp-prop-card-1-gradient pxp-animate"></div>
    <div class="pxp-prop-card-1-details">
      <div class="pxp-prop-card-1-details-title">
        <lazy-library-text
          :text="item.is_video ? 'Video' : 'Written'"
          class="tw-font-semibold tw-text-xs tw-uppercase"
        >
        </lazy-library-text>
      </div>
      <div class="pxp-prop-card-1-details-price">
        <lazy-library-title tag="h5" class="tw-font-bold" :text="item.title">
        </lazy-library-title>
      </div>
      <div
        v-if="false"
        class="pxp-prop-card-1-details-features text-uppercase"
      ></div>
    </div>
    <div class="pxp-prop-card-1-details-cta text-uppercase">
      <div
        style="color: var(--white); border-color: white"
        class="pxp-primary-cta text-uppercase pxp-animate tw-mt-5"
      >
        {{ item.cta }}
      </div>
    </div>
  </nuxt-link>
</template>

<script lang="ts">
import type { PropType } from 'vue';
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'Theme3GuideBox',

  props: {
    item: {
      type: Object as PropType<{
        cta: string;
        is_video: boolean | 1 | 0;
        title: string;
        photo: string;
        link: string;
      }>,
      required: true,
    },
    styleName: {
      type: String as PropType<'v1' | 'v2'>,
      required: false,
      default: 'v1',
    },
  },

  mixins: [ComponentMixin],
});
</script>

<style scoped>
.play-button-on-photo {
  position: absolute;
  top: 40%;
  left: 40%;
}
</style>
